<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="用户ID" prop="userId" >
        <a-input v-model="form.userId" placeholder="请输入用户ID" />
      </a-form-model-item>
      <a-form-model-item label="宠物名字" prop="petName" >
        <a-input v-model="form.petName" placeholder="请输入宠物名字" />
      </a-form-model-item>
      <a-form-model-item label="阶段" prop="stage" >
        <a-input v-model="form.stage" placeholder="请输入阶段" />
      </a-form-model-item>
      <a-form-model-item label="经验值" prop="experienceValue" >
        <a-input v-model="form.experienceValue" placeholder="请输入经验值" />
      </a-form-model-item>
      <a-form-model-item label="宠物等级" prop="grade" >
        <a-input v-model="form.grade" placeholder="请输入宠物等级" />
      </a-form-model-item>
      <a-form-model-item label="宠物信息ID" prop="petInfoId" >
        <a-input v-model="form.petInfoId" placeholder="请输入宠物信息ID" />
      </a-form-model-item>
      <a-form-model-item label="宠物图片" prop="petImage" >
        <file-upload v-model="form.petImage" type="image"></file-upload>
      </a-form-model-item>
      <a-form-model-item label="宠物头像" prop="petAvatar" >
        <a-input v-model="form.petAvatar" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <a-form-model-item label="猫粮余额" prop="catFoodBalance" >
        <a-input v-model="form.catFoodBalance" placeholder="请输入猫粮余额" />
      </a-form-model-item>
      <a-form-model-item label="猫奶蛋余额" prop="catMilkEggBalance" >
        <a-input v-model="form.catMilkEggBalance" placeholder="请输入猫奶蛋余额" />
      </a-form-model-item>
      <a-form-model-item label="小鱼干余额" prop="driedFishBalance" >
        <a-input v-model="form.driedFishBalance" placeholder="请输入小鱼干余额" />
      </a-form-model-item>
      <a-form-model-item label="服装ID" prop="clothingId" >
        <a-input v-model="form.clothingId" placeholder="请输入服装ID" />
      </a-form-model-item>
      <a-form-model-item label="猫窝ID" prop="catBoxId" >
        <a-input v-model="form.catBoxId" placeholder="请输入猫窝ID" />
      </a-form-model-item>
      <a-form-model-item label="改名卡数量" prop="changeNameCard" >
        <a-input v-model="form.changeNameCard" placeholder="请输入改名卡数量" />
      </a-form-model-item>
      <a-form-model-item label="使用免费改名" prop="useChangeName" >
        <a-input v-model="form.useChangeName" placeholder="请输入使用免费改名" />
      </a-form-model-item>
      <a-form-model-item label="备注" prop="remark" >
        <a-input v-model="form.remark" placeholder="请输入备注" />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getPet, addPet, updatePet } from '@/api/biz/pet'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        userId: null,

        petName: null,

        stage: null,

        experienceValue: null,

        grade: null,

        petInfoId: null,

        petImage: null,

        petAvatar: null,

        catFoodBalance: null,

        catMilkEggBalance: null,

        driedFishBalance: null,

        clothingId: null,

        catBoxId: null,

        changeNameCard: null,

        useChangeName: null,

        createTime: null,

        remark: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        userId: null,
        petName: null,
        stage: null,
        experienceValue: null,
        grade: null,
        petInfoId: null,
        petImage: null,
        petAvatar: null,
        catFoodBalance: null,
        catMilkEggBalance: null,
        driedFishBalance: null,
        clothingId: null,
        catBoxId: null,
        changeNameCard: null,
        useChangeName: null,
        createTime: null,
        remark: null
      }
      this.$nextTick(() => {
        if(this.$refs.form){
          this.$refs.form.resetFields()
        }
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getPet({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updatePet(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addPet(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
