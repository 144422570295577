import request from '@/utils/request'


// 查询用户宠物列表
export function listPet(query) {
  return request({
    url: '/biz/pet/list',
    method: 'get',
    params: query
  })
}

// 查询用户宠物分页
export function pagePet(query) {
  return request({
    url: '/biz/pet/page',
    method: 'get',
    params: query
  })
}

// 查询用户宠物详细
export function getPet(data) {
  return request({
    url: '/biz/pet/detail',
    method: 'get',
    params: data
  })
}

// 新增用户宠物
export function addPet(data) {
  return request({
    url: '/biz/pet/add',
    method: 'post',
    data: data
  })
}

// 修改用户宠物
export function updatePet(data) {
  return request({
    url: '/biz/pet/edit',
    method: 'post',
    data: data
  })
}

// 删除用户宠物
export function delPet(data) {
  return request({
    url: '/biz/pet/delete',
    method: 'post',
    data: data
  })
}
